import { render, staticRenderFns } from "./Selfservice.vue?vue&type=template&id=303c0974&scoped=true&"
import script from "./Selfservice.vue?vue&type=script&lang=js&"
export * from "./Selfservice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303c0974",
  null
  
)

export default component.exports